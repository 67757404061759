import type { ConfigHardware } from '../types/index.types';

const apiUrl = process.env.REACT_APP_FILLNDRIVE_API_URL;

/**
 * Get a hardware by type & serial number
 *
 * @param type - Hardware Type (char)
 * @param sn - Hardware Serial Number
 */
export const getInfo = async ( type: string, sn: string ): Promise<ConfigHardware | null> => {

  const hardwareUrl = `${apiUrl}/hardware/${type}/${sn}`;

  try {

    const res = await fetch( hardwareUrl, { method: 'GET' } );
    const data = await res.json();

    if ( data.error ) {
      return null;
    }

    // Some formatting for easy print
    delete data.id;
    data.modelReference = data.hardwareModel?.reference || null;
    data.modelMinTemp   = data.hardwareModel?.minTemp || null;
    data.modelMaxTemp   = data.hardwareModel?.maxTemp || null;
    if ( data.hardwareModel?.classEnv ) {
      data.modelClassEnv = data.hardwareModel.classEnv;
    }
    if ( data.hardwareModel?.power ) {
      data.modelPower = data.hardwareModel.power;
    }
    if ( data.hardwareModel?.certificationNumberR117 ) {
      data.modelCertR117  = data.hardwareModel.certificationNumberR117;
    }
    if ( data.hardwareModel?.certificationNumberR139 ) {
      data.modelCertR139 = data.hardwareModel.certificationNumberR139;
    }
    if ( data.hardwareModel?.products && data.hardwareModel.products.length > 0 ) {
      const myProducts: Array<{ name: string }> = data.hardwareModel.products;
      data.modelProducts = myProducts.map(o => o.name).join(', ');
    }
    if ( data.hardwareModel?.documents && data.hardwareModel.documents.length > 0 ) {
      const myDocuments: Array<any> = data.hardwareModel.documents;
      data.modelDocuments = myDocuments.map( o => {
        return {
          name: o.name,
          filename: o.media.originalFilename,
          url: `${apiUrl}/media/${o.media.id}/${o.media.originalFilename}`,
        };
      } );
    }
    delete data.hardwareModel;
    delete data.type;

    return data;

  } catch ( e ) {

    console.log( e );
    return null;

  }

};
