export const energies: { [key: string]: { [key: string]: string } } = {
	H2: {
		"200": "H20",
		"250": "H25",
		"300": "H30",
		"350": "H35",
		"500": "H50",
		"700": "H70",
		"350HF": "H35HF",
	},
};

export const getLocale = (language: string) => {
	const localeMap: { [key: string]: string } = {
		fr: "fr-FR", // Français
		en: "en-GB", // Anglais (Royaume-Uni)
		es: "es-ES", // Espagnol
		de: "de-DE", // Allemand
		it: "it-IT", // Italien
		ja: "ja-JP", // Japonais
		nl: "nl-NL", // Néerlandais
		zh: "zh-CN", // Chinois (Simplifié)
	};
	return localeMap[language] || language;
};

export const getFormattedLanguage = (language: string) => {
	const languageMap: { [key: string]: string } = {
		fr: "fr-FR",
		en: "en-GB",
		es: "es-ES",
		de: "de-DE",
		it: "it-IT",
		ja: "ja-JP",
		nl: "nl-NL",
		zh: "zh-CN",
	};
	return languageMap[language] || "en-GB";
}

export const getDate = (date: Date, timeZone: string, locale: string) =>
	new Intl.DateTimeFormat(locale, {
		dateStyle: "full",
		timeStyle: "short",
		timeZone,
	}).format(new Date(date));

export const getCurrencyNumber = (
	value: number,
	currency: string,
	locale: string,
) =>
	new Intl.NumberFormat(locale, {
		style: "currency",
		currency,
	}).format(value);

export const downloadEticket = (eTicket: string, fileName: string) => {
	const blob = new Blob([eTicket], {
		type: "text/plain",
	});
	const url = URL.createObjectURL(blob);
	const a = document.createElement("a");
	a.href = url;
	a.download = `${fileName}.txt`;
	a.click();
	URL.revokeObjectURL(url);
};
