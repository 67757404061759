import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from "./en.json";
import translationFR from "./fr.json";
import translationES from "./es.json";
import translationDE from "./de.json";
import translationIT from "./it.json";
import translationNL from "./nl.json";
import translationJA from "./ja.json";
import translationZH from "./zh.json";

// here if we have time we can build generic way to load ressources with fs.stats
const resources = {
  es: {
    translation: translationES
  },
  de: {
    translation: translationDE
  },
  it: {
    translation: translationIT
  },
  ja: {
    translation: translationJA
  },
  zh: {
    translation: translationZH
  },
  nl: {
    translation: translationNL
  },
  en: {
    translation: translationEN
  },
  fr: {
    translation: translationFR
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    //@ts-ignore
    resources,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: {
      useSuspense: false
    },
    detection: {
      order: [ 'navigator' ],
      caches: [ 'cookie' ],
    },
  });



export default i18n;
