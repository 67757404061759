import { Route, Switch } from "react-router-dom";
import Footer from "./components/Footer";
import HardwareInfo from "./components/HardwareInfo";
import Receipt from "./components/receipt/Receipt";

function App() {
	return (
		<div className="App">
			<Switch>
				<Route path="/receipt/token/:token">
					<div className="main-wrapper">
						<Receipt />
						<Footer />
					</div>
				</Route>
				<Route path="/:type/:sn">
				<div className="main-wrapper">
					<HardwareInfo />
					<Footer />
				</div>
				</Route>
				<Route path="/:sn">
				<div className="main-wrapper">
					<HardwareInfo boxMode={true} />
					<Footer />
				</div>
				</Route>
			</Switch>
		</div>
	);
}

export default App;
