import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  CircularProgress,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Alert from "@mui/lab/Alert";
import logo from "../logo.png";
import "./style.scss";
import type { ConfigHardware, MediaInfo } from "../types/index.types";
import { getInfo } from "../services/HardwareInfo";
import Medias from "./Medias";

// Notes:
//      - when boxMode active: ':id' is same as ':serialNumber'
//      - look for 'box' and 'dls' (types 'b' & 'd')

const HardwareInfo: React.FC<{ boxMode?: boolean }> = ({ boxMode }) => {
  // @ts-ignore
  let { type, sn } = useParams();

  if (boxMode) {
    type = "b,d";
  }

  const { t } = useTranslation();

  const [info, setInfo] = useState<ConfigHardware | null>(null);
  const [documents, setDocuments] = useState<Array<MediaInfo>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      try {
        const info: ConfigHardware | null = await getInfo(type, sn);
        if (info !== null) {
          const maxTemp = info.modelMaxTemp;
          const minTemp = info.modelMinTemp;
          delete info.modelMaxTemp;
          delete info.modelMinTemp;
          if (maxTemp != null && minTemp != null) {
            info.temperature = t("hardware.fields.temperature_value", {
              maxTemp,
              minTemp,
            });
          } else if (maxTemp != null) {
            info.temperature = t("hardware.fields.temperature_value_max", {
              maxTemp,
            });
          } else if (minTemp != null) {
            info.temperature = t("hardware.fields.temperature_value_min", {
              minTemp,
            });
          }

          const documents = info.modelDocuments || [];
          delete info.modelDocuments;
          setDocuments(documents);

          setInfo(info);
          document.title = t(`${boxMode ? "box" : "hardware"}.title`, {
            sn: info.serialNumber,
          });
        }
      } catch (error) {
        console.error("Failed to fetch hardware info", error);
        setInfo(null);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [sn, type, t, boxMode]);

  const order: (keyof ConfigHardware)[] = [
    "manufactureYear",
    "modelReference",
    "modelCertR117",
    "modelCertR139",
    "serialNumber",
    "modelProducts",
    "temperature",
    "modelClassEnv",
    "modelPower",
  ];

  return (
    <Container maxWidth="sm" className="hardware">
      <div className="box_header">
        <img src={logo} className="App-logo" alt="logo" />
      </div>

      {isLoading ? (
        <CircularProgress />
      ) : info ? (
        <div>
          <h1>
            {t(`${boxMode ? "box" : "hardware"}.title`, {
              sn: info.serialNumber,
            })}
          </h1>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("hardware.field")}</TableCell>
                  <TableCell align="right">{t("hardware.value")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order.map((field) => {
                  const value = info[field];
                  if (value) {
                    return (
                      <TableRow key={field}>
                        <TableCell>{t(`hardware.fields.${field}`)}</TableCell>
                        <TableCell align="right">{t(value)}</TableCell>
                      </TableRow>
                    );
                  }
                  return null;
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Medias medias={documents} />
        </div>
      ) : (
        <Alert severity="error">
          {t(`${boxMode ? "box" : "hardware"}.unknown`)}
        </Alert>
      )}
    </Container>
  );
};

export default HardwareInfo;
