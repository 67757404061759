import InfoIcon from "@mui/icons-material/Info";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import type { ReceiptNotFoundProps } from "../../types/index.types";
import { LogoImage } from "./Receipt";

export const ReceiptNotFound = ({
	data,
	handleSubmit,
	isEmailLoading,
	refForm
}: ReceiptNotFoundProps) => {
	const { t } = useTranslation();
	if (!data) return null;
	return (
		<div className="not-found-container">
			<div className="not-found-message-wrapper">
				<InfoIcon className="icon-info" />
				<div className="not-found-message-messages">
					<div className="not-found-title-message">
						{t("receipt.not_found_title_message")}
					</div>
					<div className="not-found-subtitle-message">
						{t("receipt.not_found_subtitle_message")}
					</div>
				</div>
			</div>
			<Card className="card-container">
				<div className="receipt-container">
					<div className="nav">
						<LogoImage operatorLogo={data.operatorLogo} />
						<h1>{t("receipt.title")}</h1>
					</div>
					<div className="header">
						<div className="field">
							<div>{t("receipt.station")}:</div>
							<div>{data.stationName}</div>
						</div>
						<div className="field">
							<div>{t("receipt.address")}:</div>
							<div>{data.stationAddress}</div>
						</div>
					</div>
				</div>
				<form ref={refForm} className="email-container" onSubmit={handleSubmit}>
					<input
						type="email"
						required
						placeholder={t("receipt.your_email_address")}
						name="email"
					/>
					<button type="submit">
						{isEmailLoading ? (
							<CircularProgress style={{ color: "white" }} size={14} />
						) : (
							t("receipt.send")
						)}
					</button>
					<small className="rgdp-info">
						{t("receipt.rgpd", { operator: "FillnDrive" })}
					</small>
				</form>
				<div className="footer-container">
					<h3>{data.operatorReceiptMessage ?? t("receipt.thanks_message")}</h3>
				</div>
			</Card>
		</div>
	);
};
