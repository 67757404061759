import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import type { MediaInfo } from '../types/index.types';

interface MediasProps {
  medias: Array<MediaInfo>;
}
const Medias: React.FC<MediasProps> = ({medias}) => {

  const { t } = useTranslation();

  const [ documents, setDocuments ] = useState<Array<MediaInfo>>([]);

  useEffect(
    () => {
      if (medias.length > 0) {
        setDocuments( medias );
      }
    },
    [medias],
  );

  return (
    documents.length > 0 ?
      <div style={{marginTop: '1rem'}}>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableCell>{ t( 'hardware.fields.documents' ) }</TableCell>
              <TableCell />
            </TableHead>
            <TableBody>
              {
                documents.map( (media, index) => (
                  <TableRow key={`${media.name}-${index}`}>
                    <TableCell>{ t( media.name ) }</TableCell>
                    <TableCell align="right"><Link href={ media.url } target="_blank" rel="noopener" underline="always">{ media.filename }</Link></TableCell>
                  </TableRow>
                ) )
              }
            </TableBody>
          </Table>
        </TableContainer>
      </div> : null
  )
}

export default Medias;
