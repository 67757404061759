import "./style.scss";
import { useTranslation } from "react-i18next";

const languages = [
	{ code: "fr-FR", label: "Français" },
	{ code: "en-GB", label: "English" },
	{ code: "es-ES", label: "Español" },
	{ code: "de-DE", label: "Deutsch" },
	{ code: "it-IT", label: "Italiano" },
	{ code: "ja-JP", label: "日本語" },
	{ code: "nl-NL", label: "Nederlands" },
	{ code: "zh-CN", label: "中文" },
];

const Footer = () => {
	const { i18n } = useTranslation();	
	return (
		<div className="footer">
			{languages.map(({ code, label }) => (
				<button
					key={code}
					type="button"
					className={`footer_link ${i18n.language === code ? "footer_link--selected" : ""}`}
					onClick={() => i18n.changeLanguage(code)}
				>
					{label}
				</button>
			))}
		</div>
	);
};

export default Footer;
