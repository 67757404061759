import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

type SimpleDialogProps = {
	open: boolean;
	title: string;
	content: React.ReactNode;
	onClose: () => void;
	primaryButtonTitle?: string;
	secondaryButtonTitle?: string;
	onHandlePrimaryButton?: () => void;
	onHandleSecondaryButton?: () => void;
};

const SimpleDialog = ({
	open,
	title,
	content,
	onClose,
	primaryButtonTitle,
	secondaryButtonTitle,
	onHandlePrimaryButton,
	onHandleSecondaryButton,
}: SimpleDialogProps) => {
	const renderDialogActions = () => {
		if (!primaryButtonTitle && !secondaryButtonTitle) return null;
		return (
			<DialogActions>
				{primaryButtonTitle && (
					<Button onClick={onHandlePrimaryButton}>{primaryButtonTitle}</Button>
				)}
				{secondaryButtonTitle && (
					<Button onClick={onHandleSecondaryButton}>
						{secondaryButtonTitle}
					</Button>
				)}
			</DialogActions>
		);
	};
	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>{title}</DialogTitle>
			<IconButton
				aria-label="close"
				onClick={onClose}
				sx={{
					position: "absolute",
					right: 8,
					top: 8,
					color: (theme) => theme.palette.grey[500],
				}}
			>
				<CloseIcon />
			</IconButton>
			<DialogContent dividers>{content}</DialogContent>
			{renderDialogActions()}
		</Dialog>
	);
};

export default SimpleDialog;
